import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { GlobalCacheService } from '@core/services/global-cache.service';
import * as AuthActions from '@core/store/auth/auth.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class AppInitializerService {
  constructor(
    private globalCacheService: GlobalCacheService,
    private store: Store
  ) {}

  public load(): Promise<any> {
    this._initSynchronyServices();
    this._initUserIfTokenExist();

    return Promise.resolve();
  }

  private _initSynchronyServices(): void {
    this.globalCacheService.init();
  }

  private _initUserIfTokenExist(): void {
    this.store.dispatch(AuthActions.InitAuthUserAction());
  }
}

export function initializeFactoryFunc(appInitializerService: AppInitializerService) {
  return () => appInitializerService.load();
}

export const APP_INITIALIZER_PROVIDERS: Provider[] = [
  AppInitializerService,
  {
    provide: APP_INITIALIZER,
    useFactory: initializeFactoryFunc,
    deps: [AppInitializerService],
    multi: true,
  },
];
