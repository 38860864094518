import { Inject, Injectable } from '@angular/core';
import { WINDOW_TOKEN } from '@core/window/window.service';
import { Observable, ReplaySubject } from 'rxjs';

export interface IStorageInfo {
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storageSub = new ReplaySubject<IStorageInfo>(1);

  constructor(@Inject(WINDOW_TOKEN) private win: Window) {}

  public watchChangedStorage(): Observable<IStorageInfo> {
    return this.storageSub.asObservable();
  }

  public getItem(key: string, parsed?: boolean): any {
    let item = this.win.localStorage.getItem(key) as string;
    if (parsed) {
      item = JSON.parse(item);
    }
    return item;
  }

  public setItem(key: string, value: any): void {
    if (this.getItem(key) === value) return;

    this.storageSub.next({
      key,
      value,
    } as IStorageInfo);

    this.win.localStorage.setItem(key, value);
  }

  public removeItem(key: string): void {
    this.storageSub.next({
      key,
      value: '',
    });

    this.win.localStorage.removeItem(key);
  }

  public clearStorage(): void {
    this.win.localStorage.clear();
  }
}
