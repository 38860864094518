import { Component, OnInit } from '@angular/core';
import * as AuthActions from '@app-store/auth/auth.actions';
import { AuthService } from '@auth0/auth0-angular';
import { ThemeService } from '@core/services/theme.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'bee-smart';
  private logged = false;

  constructor(
    private themeService: ThemeService,
    private auth: AuthService,
    private store: Store
  ) {
    this.auth.isAuthenticated$
      .pipe(
        untilDestroyed(this),
        filter(isAuthenticated => !!isAuthenticated && isAuthenticated),
        switchMap(() => this.auth.getAccessTokenSilently())
      )
      .subscribe(accessToken => {
        this.store.dispatch(AuthActions.LogInResponseAction({ accessToken }));
        if (!this.logged) {
          this.logged = true;
          this.store.dispatch(AuthActions.LogInSuccessAction());
        }
      });
  }

  ngOnInit(): void {
    this.themeService.defineTheme();
  }
}
