import { Injectable } from '@angular/core';
import { BuildingApiService } from '@api/building-api.service';
import { EnergyApiService } from '@api/energy-api.service';
import { InspectionsApiService } from '@api/inspections-api.service';
import { selectProposalId } from '@app-store/_proposal/_proposal.reducer';
import * as BuildingsActions from '@app-store/buildings/buildings.actions';
import { IBuildingMainData } from '@common/interfaces/building-response.interface';
import { IBuildingInspection } from '@common/interfaces/inspections.interface';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, mergeMap, switchMap } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import * as BuildingActions from './building.actions';

@Injectable()
export class BuildingEffects {
  constructor(
    private actions$: Actions,
    private buildingApiService: BuildingApiService,
    private inspectionsApiService: InspectionsApiService,
    private store: Store,
    private energyApiService: EnergyApiService
  ) {}

  SetSelectedBuildingIdAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.SetSelectedBuildingIdAction),
      map(res => {
        return res;
      })
    );
  });

  GetBuildingDataAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.GetBuildingDataAction),
      filter(res => !!res),
      switchMap(({ buildingId }) => this.buildingApiService.getBuildingMainData(buildingId!)),
      map((buildingData: IBuildingMainData) => {
        return BuildingActions.SetBuildingDataAction({ buildingData });
      })
    );
  });

  GetBuildingDepartmentDataAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.GetBuildingDepartmentDataAction),
      mergeMap(({ buildingId, departmentId }) => {
        return this.buildingApiService.getBuildingCategoriesData(buildingId, departmentId).pipe(
          map(res => {
            return { departmentData: res ? res : [], departmentId };
          })
        );
      }),
      map(({ departmentData, departmentId }) => {
        if (departmentId === 1) {
          return BuildingActions.SetBuildingEnergyDepartmentDataAction({ energy: departmentData });
        } else if (departmentId === 2) {
          return BuildingActions.SetBuildingSustainabilityDepartmentDataAction({ sustainability: departmentData });
        } else {
          return BuildingActions.SetBuildingEnvelopDepartmentDataAction({ envelop: departmentData });
        }
      })
    );
  });

  AddBuildingDepartmentAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.AddBuildingDepartmentAction),
      switchMap(({ buildingId, departmentId }) =>
        this.buildingApiService.addDepartmentToBuilding(buildingId, departmentId).pipe(
          map(() => ({
            buildingId,
          }))
        )
      ),
      map(({ buildingId }) => BuildingActions.GetBuildingDataAction({ buildingId }))
    );
  });

  RemoveBuildingDepartmentAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.RemoveBuildingDepartmentAction),
      switchMap(({ buildingId, departmentId }) =>
        this.buildingApiService.deleteDepartmentOfBuilding(buildingId, departmentId).pipe(
          map(() => ({
            buildingId,
          }))
        )
      ),
      map(({ buildingId }) => BuildingActions.GetBuildingDataAction({ buildingId }))
    );
  });

  saveBuildingDataAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.AddBuildingDataAction),
      switchMap(({ buildingData }) => this.buildingApiService.addBuildingToProposal(buildingData)),
      switchMap(() => this.store.select(selectProposalId).pipe(first())),
      map(proposalId => {
        return BuildingsActions.GetBuildingsListAction({ proposalId: proposalId! });
      })
    );
  });

  updateBuildingDataAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.UpdateBuildingDataAction),
      switchMap(({ buildingData, buildingId }) => this.buildingApiService.updateBuildingData(buildingId, buildingData)),
      switchMap(() => this.store.select(selectProposalId).pipe(first())),
      map(proposalId => {
        return BuildingsActions.GetBuildingsListAction({ proposalId: proposalId! });
      })
    );
  });

  addOrRemoveBuildingDepartmentServiceDataAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.AddOrRemoveBuildingDepartmentServiceDataAction),
      switchMap(({ status, categoryId, buildingId, departmentId }) =>
        defer(() =>
          !status
            ? this.buildingApiService.addCategoryToBuilding(buildingId, categoryId).pipe(
                map(() => ({
                  buildingId,
                  departmentId,
                }))
              )
            : this.buildingApiService
                .deleteCategoryFromBuilding(buildingId, categoryId)
                .pipe(map(() => ({ buildingId, departmentId })))
        ).pipe(
          map(({ buildingId, departmentId }) => {
            return BuildingActions.GetBuildingDepartmentDataAction({
              buildingId,
              departmentId,
            });
          })
        )
      )
    );
  });

  GetBuildingDataInspections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.GetBuildingDataInspections),
      filter(res => !!res),
      switchMap(({ buildingId }) => this.inspectionsApiService.getBuildingInspections(buildingId!)),
      map((inspections: IBuildingInspection[]) => {
        return BuildingActions.SetBuildingDataInspections({ inspections });
      })
    );
  });

  //   EnergyCode

  saveBuildingEnergyCodeDataAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingActions.SaveBuildingEnergyCodeDataAction),
      switchMap(({ buildingId, data, saveService }) =>
        defer(() =>
          saveService
            ? this.energyApiService.updateBuildingEnergyCode(buildingId!, data)
            : this.energyApiService.saveBuildingEnergyCode(buildingId!, data)
        )
      )
    );
  });
}
