import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICredentials, IToken } from '@common/interfaces/auth.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthCustomService {
  constructor(private http: HttpClient) {}

  login(credential: ICredentials): Observable<IToken> {
    return this.http.post<IToken>('login', credential);
  }

  refreshToken(): Observable<any> {
    return this.http.post('refresh-access-token', {}, { withCredentials: true });
  }
}
