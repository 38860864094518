import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthCustomService } from '@api/auth.service';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalCacheService } from '@core/services/global-cache.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, first, map, of, switchMap, tap } from 'rxjs';
import * as AppActions from '../app/app.actions';
import * as AuthActions from './auth.actions';
import { selectAccessToken } from './auth.reducers';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthCustomService,
    private store: Store,
    private storageService: LocalStorageService,
    private router: Router,
    private globalCacheService: GlobalCacheService,
    private auth: AuthService
  ) {}

  loginRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.LogInRequestAction),
      switchMap(({ credentials }) =>
        this.authService.login(credentials).pipe(
          switchMap(({ accessToken }) =>
            of(AuthActions.LogInResponseAction({ accessToken }), AuthActions.LogInSuccessAction())
          ),
          catchError(error => of(AuthActions.LogInResponseErrorAction(error)))
        )
      )
    );
  });

  loginResponse$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.LogInSuccessAction)
        // tap(() => this.router.navigateByUrl('/'))
      );
    },
    { dispatch: false }
  );

  // loginResponseError$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(AuthActions.LogInResponseErrorAction),
  //       switchMap(() => {
  //         return EMPTY;
  //       })
  //     );
  //   },
  //   { dispatch: false }
  // );

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.LogOutAction),
      tap(() => {
        this.storageService.clearStorage();
        this.auth.logout();
        this.router.navigateByUrl('auth').then(() => {
          this.globalCacheService.removeAll();
          this.globalCacheService.init();
        });
      }),
      switchMap(() => of(AppActions.ClearState()))
    );
  });

  // Load user if token exists (temporary solution, should be refactored in the future)
  checkToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.InitAuthUserAction),
      switchMap(() => this.store.select(selectAccessToken).pipe(first())),
      filter(accessToken => !!accessToken),
      map(accessToken => AuthActions.LogInResponseAction({ accessToken: accessToken as string }))
    );
  });
}
