import { IBaseRFPRequestData, IProposalBaseData } from '@common/interfaces/proposal.interface';
import { createAction, props } from '@ngrx/store';

export const ClearProposalStoreAction = createAction('[Proposal] Clear Proposal Store');
export const LoadProposalErrorAction = createAction('[Proposal] Load Proposal Error', props<{ error: string }>());
export const GetProposalMainDataAction = createAction(
  '[Proposal] Get Proposal Main Data',
  props<{ proposalId: number }>()
);
export const SetProposalMainDataAction = createAction(
  '[Proposal] Set Proposal Main Data',
  props<{
    proposalMainData: IProposalBaseData;
    proposalId: number;
  }>()
);

export const SaveProposalMainDataAction = createAction(
  '[Proposal] Save Proposal Main Data',
  props<{
    proposalFormMainData: IBaseRFPRequestData;
  }>()
);

export const UpdateProposalMainDataAction = createAction(
  '[Proposal] Update Proposal Main Data',
  props<{
    proposalFormMainData: IBaseRFPRequestData;
    proposalId: number;
  }>()
);

export const UpdateProposalCodesAndNormatives = createAction(
  '[Proposal] Update Proposal Codes and Normatives',
  props<{
    proposalId: number;
    data: any;
  }>()
);

// export const DeleteProposalCategoryAndNormatives = createAction(
//   '[Proposal] Delete Proposal Codes and Normatives',
//   props<{
//     id: number;
//   }>()
// );

export const GetProposalCodesAndNormatives = createAction(
  '[Proposal] Get Proposal Codes and Normative',
  props<{
    proposalId: number;
  }>()
);

export const SetProposalCodesAndNormatives = createAction(
  '[Proposal] Set Proposal Codes and Normative',
  props<{
    codesAndNormatives: any[];
  }>()
);

export const DeleteProposalCodesAndNormatives = createAction(
  '[Proposal] Delete Proposal Codes and Normative',
  props<{
    codesAndNormativeId: number;
    proposalId: number;
  }>()
);
