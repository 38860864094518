import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { IBuildingEnergyCode, IEnergyCodeAdvanced } from '@common/interfaces/energy-code.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnergyApiService {
  constructor(private http: HttpClient) {}

  public getEnergyCodesList(params?: IParamsRequestKeys): Observable<IRes<IEnergyCodeAdvanced>> {
    return this.http.get<IRes<IEnergyCodeAdvanced>>('energy-codes', { params });
  }

  public getEnergyCodeById(id: number): Observable<IEnergyCodeAdvanced> {
    return this.http.get<IEnergyCodeAdvanced>(`energy-code/${id}`);
  }

  public saveEnergyCode(energyCode: IEnergyCodeAdvanced): Observable<any> {
    return this.http.post('energy-code', energyCode);
  }

  public updateEnergyCode(energyCode: IEnergyCodeAdvanced): Observable<any> {
    return this.http.patch(`energy-code/${energyCode.id}`, energyCode);
  }

  public deleteEnergyCode(id: number): Observable<any> {
    return this.http.delete(`energy-code/${id}`);
  }

  public getEnergyCodesByRfpId(rfpId: number): Observable<IRes<IEnergyCodeAdvanced>> {
    return this.http.get<IRes<IEnergyCodeAdvanced>>(`rfp-energy-code/${rfpId}`);
  }

  public saveBuildingEnergyCode(buildingId: number, data: IBuildingEnergyCode): Observable<any> {
    return this.http.post(`building/${buildingId}/energy-code`, data);
  }

  public updateBuildingEnergyCode(buildingId: number, data: IBuildingEnergyCode): Observable<any> {
    return this.http.patch(`building/${buildingId}/energy-code`, data);
  }

  public getEnergyCodesByState(params: IParamsRequestKeys): Observable<any> {
    // state: string
    return this.http.get('energy-codes-state', { params });
  }
}
