import { IBuildingResponse } from '@common/interfaces/building-response.interface';
import { createAction, props } from '@ngrx/store';

export const ClearBuildingsDataStoreAction = createAction('[buildingsData] Clear Buildings Store');
export const LoadBuildingErrorAction = createAction('[buildingsData] Load Building Error', props<{ error: string }>());
export const GetBuildingsListAction = createAction(
  '[buildingsData] Get Building List Data',
  props<{
    proposalId: number;
  }>()
);

export const SetSelectedBuildingIdAction = createAction(
  '[buildingsData] Set Selected Building Id Action',
  props<{
    selectedBuildingId: number | any;
  }>()
);

export const SetBuildingsListAction = createAction(
  '[buildingsData] Set Building List Data',
  props<{
    buildingsList: IBuildingResponse[];
    selectedBuildingId: number | null;
  }>()
);
