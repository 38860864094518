import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IBaseData, IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { IUserRole } from '@common/interfaces/user.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RolesApiService {
  constructor(private http: HttpClient) {}

  public getRolesList(params?: IParamsRequestKeys): Observable<IRes<IUserRole>> {
    return this.http.get<IRes<IUserRole>>('roles', { params });
  }

  public addRole(role: any): Observable<any> {
    return this.http.post(`role`, role);
  }

  public updateRole(role: any, id: string | number): Observable<any> {
    return this.http.patch(`role/${id}`, role);
  }

  public deleteRole(id: number): Observable<any> {
    return this.http.delete(`role/${id}`);
  }

  public getAccessLevelsList(): Observable<IBaseData> {
    return this.http.get<IBaseData>('clearance-levels');
  }
}
