import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[click-outside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Output() outsideClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  private clickEventListener!: EventListener | any;
  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.clickEventListener = this.onClick.bind(this);
    // document.addEventListener('click', this.clickEventListener);

    setTimeout(() => {
      document.addEventListener('click', this.clickEventListener);
    });
  }

  onClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.outsideClick.emit();
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.clickEventListener);
  }
}
