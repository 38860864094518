import { IRFPData } from '@common/interfaces/proposal.interface';
import { Dayjs } from 'dayjs';

export interface IBaseData {
  id: number | string;
  name: string;
  disabled?: boolean;

  [key: string]: any;
}

export type TDateOrString = Date | string | any;

export interface IParamsRequest {
  id: number;
  pagination: number | string | boolean;
  limit: number | string;
  page: number;
  type?: string;
}

export interface IParamsRequestKeys extends Partial<IParamsRequest> {
  [key: string]: any;
}

export type IModalType = 'create' | 'edit' | 'view';

export interface IBaseModal {
  title?: string;
  modalType?: IModalType;
  question?: string;
  updateAfterClose?: boolean;
  text?: string;
  errors?: any;
  originalRequestError?: any;
  errorMessage?: boolean;
  data?: {
    id?: number;
    userId?: string;
    input_label?: string;
    input_name?: string;
    input_placeholder?: string;
    input_value?: string | null;
    formData?: any;
    onlyNumber?: boolean;
    rfpId?: number;
    serviceId?: number;
    textarea?: boolean;
    proposalData?: IRFPData;
    selectItemsList?: Partial<IBaseData>[];
    [key: string]: any;
  };
}

export interface IDataAfterCloseModal {
  isUpgrade: boolean | undefined;
  data?: any | null;
}

export interface SetupTopMenu {
  id?: number;
  name: string;
  link?: string;
  accessGroup?: string;
  accessPermissionAction?: string;
  active?: boolean;
}

export interface IRangerString {
  [key: string]: string;
}

export interface IRanger {
  [key: string]: Dayjs;
}
