import { IBuildingMainData, IBuildingResponse } from '@common/interfaces/building-response.interface';
import { IBuildingCategory } from '@common/interfaces/departments.interface';
import { IBuildingEnergyCode } from '@common/interfaces/energy-code.interface';
import { IBuildingInspection } from '@common/interfaces/inspections.interface';
import { IRFPBuilding } from '@common/interfaces/proposal.interface';
import { createAction, props } from '@ngrx/store';

export const ClearBuildingDataStoreAction = createAction('[buildingData] Clear Building Store');
export const LoadBuildingErrorAction = createAction('[buildingData] Load Building Error', props<{ error: string }>());

export const SetSelectedBuildingIdAction = createAction(
  '[buildingData] Set Selected Building Id Action',
  props<{
    buildingId: number | null;
  }>()
);

export const GetBuildingDataAction = createAction(
  '[buildingData] Get Building Data Action',
  props<{
    buildingId: number | null;
  }>()
);

export const AddBuildingDataAction = createAction(
  '[buildingData] Add Building Data Action',
  props<{
    buildingData: IRFPBuilding | IBuildingResponse;
  }>()
);

export const UpdateBuildingDataAction = createAction(
  '[buildingData] Update Building Data Action',
  props<{
    buildingId: number;
    buildingData: IRFPBuilding | IBuildingResponse;
  }>()
);

export const SetBuildingDataAction = createAction(
  '[buildingData] Set Building Data Action',
  props<{
    buildingData: IBuildingMainData | null;
  }>()
);

export const GetBuildingDepartmentDataAction = createAction(
  '[buildingDepartmentData] Get Building Department Data Action',
  props<{
    buildingId: number;
    departmentId: number;
  }>()
);

export const AddBuildingDepartmentAction = createAction(
  '[buildingDepartmentData] Add Building Department Action',
  props<{
    buildingId: number;
    departmentId: number;
  }>()
);

export const RemoveBuildingDepartmentAction = createAction(
  '[buildingDepartmentData] Remove Building Department Action',
  props<{
    buildingId: number;
    departmentId: number;
  }>()
);

// export const DeleteBuildingDepartmentDataAction = createAction() //addDepartmentToBuilding

export const AddOrRemoveBuildingDepartmentServiceDataAction = createAction(
  '[buildingDepartmentData] Add Or Remove Building Department Service Data Action',
  props<{
    status: boolean;
    categoryId: number;
    buildingId: number;
    departmentId: number;
  }>()
);

// Energy
export const SetBuildingEnergyDepartmentDataAction = createAction(
  '[buildingEnergyDepartmentData] Set Building Energy Department Data Action',
  props<{
    energy: IBuildingCategory[];
  }>()
);

export const SaveBuildingEnergyCodeDataAction = createAction(
  '[buildingEnergyDepartmentData] Save Building Energy Code Data Action',
  props<{
    buildingId?: number;
    data: IBuildingEnergyCode;
    saveService: boolean;
  }>()
);

export const SetBuildingEnergyCodeDataAction = createAction(
  '[buildingEnergyDepartmentData] Save Building Energy Code Data Action',
  props<{
    energyCodeData?: number;
  }>()
);

// Sustainability
export const SetBuildingSustainabilityDepartmentDataAction = createAction(
  '[buildingSustainabilityDepartmentData] Set Building Sustainability Department Data Action',
  props<{
    sustainability: IBuildingCategory[];
  }>()
);

export const SetBuildingEnvelopDepartmentDataAction = createAction(
  '[buildingSustainabilityDepartmentData] Set Building Sustainability Department Data Action',
  props<{
    envelop: IBuildingCategory[];
  }>()
);

// Inspections

export const GetBuildingDataInspections = createAction(
  '[buildingInspectionDepartmentData] Get Building Data Inspections',
  props<{
    buildingId: number | null;
  }>()
);

export const SetBuildingDataInspections = createAction(
  '[buildingInspectionDepartmentData] Set Building Data Inspections',
  props<{
    inspections: IBuildingInspection[];
  }>()
);
