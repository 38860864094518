import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { IUser, IUserData } from '@common/interfaces/user.interface';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  public getUsers(params?: IParamsRequestKeys): Observable<IRes<IUser>> {
    return this.http.get<IRes<IUser>>('users', { params });
  }

  public addUser(user: any): Observable<any> {
    return this.http.post('register', user);
  }

  public getUserData(id: number | string): Observable<IUser> {
    return this.http.get<IUser>(`user/${id}`);
  }

  public updateUser(user: IUser): Observable<IUser> {
    return this.http.patch<IUser>(`change-data/${user.id}`, user);
  }

  public updatePassword(password: string): Observable<any> {
    return this.http.patch(`change-password`, { password });
  }

  public deleteUser(id: number | string): Observable<any> {
    return this.http.delete(`user/${id}`);
  }

  public getProfile(): Observable<IUserData> {
    return this.http.get<IUserData>(`get-profile`);
  }
}
