import { Component, Input } from '@angular/core';

@Component({
  selector: 'primary-btn',
  templateUrl: './primary-btn.component.html',
  styleUrls: ['./primary-btn.component.scss'],
})
export class PrimaryBtnComponent {
  @Input() public className: 'blue' | 'grey' = 'blue';
  @Input() public disabled = false;
}
