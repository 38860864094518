import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDepartment, IDepartmentAssignedFlag } from '@common/interfaces/department-groups.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DepartmentsApiService {
  constructor(private http: HttpClient) {}

  public getDepartmentsList(): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>('departments');
  }

  public getDepartmentsVsGroupsList(): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>('departments-groups');
  }

  public getDepartmentAssignedFlag(id: number): Observable<IDepartmentAssignedFlag> {
    return this.http.get<IDepartmentAssignedFlag>(`department-assigned/${id}`);
  }

  public addDepartment(department: IDepartment): Observable<any> {
    return this.http.post<IDepartment[]>('department', department);
  }

  public updateDepartment(department: IDepartment, id: number): Observable<any> {
    return this.http.put<IDepartment[]>(`department/${id}`, department);
  }

  public deleteDepartment(id: number): Observable<any> {
    return this.http.delete(`department/${id}`);
  }

  public getDepartmentServices(): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>('departments-services');
  }
}
