import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IBuildingInspection,
  IInspectionsListInterface,
  IUpdateInspection,
} from '@common/interfaces/inspections.interface';
import { ITripResponse } from '@common/interfaces/trip.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InspectionsApiService {
  constructor(private http: HttpClient) {}

  public getInspectionsList(): Observable<IInspectionsListInterface[]> {
    return this.http.get<IInspectionsListInterface[]>('inspection-line-item-templates');
  }

  public addInspection(data: IInspectionsListInterface): Observable<any> {
    return this.http.post('inspection', data);
  }

  public updateInspection(data: IInspectionsListInterface, id: number): Observable<any> {
    return this.http.put(`inspection/${id}`, data);
  }

  public deleteInspection(id: number): Observable<any> {
    return this.http.delete(`inspection/${id}`);
  }

  public getBuildingInspections(buildingId: number): Observable<IBuildingInspection[]> {
    return this.http.get<IBuildingInspection[]>(`building-inspections/${buildingId}`);
  }

  public addInspectionToBuilding(buildingId: number, data: any): Observable<any> {
    return this.http.post(`building/${buildingId}/inspection`, data);
  }

  public updateInspectionToBuilding(inspection_id: number, data: IUpdateInspection): Observable<any> {
    return this.http.put(`building-inspection/${inspection_id}`, data);
  }

  public getBuildingInspectionsTrips(building_id: number): Observable<ITripResponse[]> {
    return this.http.get<ITripResponse[]>(`trip-costs/${building_id}`);
  }

  public deleteBuildingInspection(id: number): Observable<any> {
    return this.http.delete(`building-inspection/${id}`);
  }
}
