import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IService, IServiceConfig } from '@common/interfaces/service.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ServicesApiService {
  constructor(private http: HttpClient) {}

  public getServicesList(): Observable<IService[]> {
    return this.http.get<IService[]>('services');
  }

  public getRfpServiceConfig(department_id: number, service_id: number): Observable<IServiceConfig> {
    return this.http.get<IServiceConfig>(`service-configs/${department_id}/${service_id}`);
  }
}
