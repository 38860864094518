import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IBaseModal } from '@common/interfaces/base.interfaces';
import { InfoModalComponent } from '@common/modules/modals/info-modal/info-modal.component';
import { InterceptError } from '@core/interceptors/error/erorr.model';
import { environment } from '@environments/environment';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class GlobalHttpModalError implements HttpInterceptor {
  constructor(private dialog: MatDialog) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = request.url;
    const checkIsRequestUrlToBackend = !requestUrl.match(/(assets|\.json)/) && !requestUrl.match(environment.host);

    if (checkIsRequestUrlToBackend) {
      return next.handle(request).pipe(
        catchError((errorMessage: string) => {
          this.openErrorModal({
            title: !environment.production ? `Request: ${requestUrl}` : 'Error',
            text: errorMessage,
            errorMessage: true,
          });
          return throwError(new InterceptError(errorMessage));
        })
      );
    }

    return next.handle(request);
  }

  private openErrorModal(modalData: IBaseModal): void {
    this.dialog.open<InfoModalComponent, IBaseModal>(InfoModalComponent, {
      data: modalData,
    });
  }
}
