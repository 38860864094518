<header class="flex-row justify-space-between align-items-center">
  <div class="link logo" [routerLink]="'/'">BEE.Smart</div>

  <div class="flex-row justify-right w-33 align-items-center">
    <ul class="flex-row justify-right menu align-items-center"></ul>
    <!-- uncomment it for testing -->
    <!-- <button style="cursor: pointer" (click)="sentTestNotification()">SEND ME TEST NOTIFICATION</button> -->

    <div class="user flex-row align-items-center">
      <ng-container *ngIf="userData$ | async as userData">
        <!--        <a [routerLink]="['', 'site-settings']">-->
        <!--          <mat-icon class="site-settings">settings</mat-icon>-->
        <!--        </a>-->

        <app-notifications-icon
          class="notifications"
          [hasUnreadNotifications]="hasUnreadNotifications"
          (click)="toggleNotifications()"></app-notifications-icon>

        <app-notifications-list
          *ngIf="isNotificationsOpen"
          class="app-notifications-list"
          [notificationsList]="notificationsList"
          [hasUnreadNotifications]="hasUnreadNotifications"
          (closeNotifications)="hideNotifications()"
          click-outside
          (outsideClick)="hideNotifications()"></app-notifications-list>

        <div class="flex-row align-items-center" (click)="toggleMenu()">
          <!--          <span class="user-name">{{ userData?.first_name }} {{ userData?.last_name }}</span>-->
          <div class="user-img">
            <user-img></user-img>
          </div>
        </div>

        <ul class="settings" *ngIf="isUserMenuOpen" click-outside (outsideClick)="hideMenu()">
          <li [routerLink]="['', 'profile']">Profile</li>
          <li (click)="logOut()">Logout</li>
        </ul>
      </ng-container>
    </div>
  </div>
</header>
