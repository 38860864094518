import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import {
  IBuildingMainData,
  IBuildingResponse,
  IBuildingServiceStatus,
} from '@common/interfaces/building-response.interface';
import { IBuildingCategory } from '@common/interfaces/category.interface';
import { IBuildingDepartment } from '@common/interfaces/departments.interface';
import { IRFPBuilding } from '@common/interfaces/proposal.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BuildingApiService {
  constructor(private http: HttpClient) {}

  public addBuildingToProposal(building: IRFPBuilding | IBuildingResponse): Observable<any> {
    return this.http.post('building', building);
  }

  public getProposalBuildings(rfpID: number): Observable<IRes<IBuildingResponse>> {
    return this.http.get<IRes<IBuildingResponse>>(`rfp-buildings/${rfpID}`);
  }

  public getBuildingData(buildingID: number): Observable<IBuildingResponse> {
    return this.http.get<IBuildingResponse>(`building/${buildingID}`);
  }

  public getBuildingMainData(buildingId: number): Observable<IBuildingMainData> {
    return this.http.get<IBuildingMainData>(`building-ref/${buildingId}`);
  }

  public getBuildingDepartmentData(
    buildingId: number,
    department_id: number
  ): Observable<{
    services: IBuildingDepartment[];
  }> {
    return this.http.get<{ services: IBuildingDepartment[] }>(`building/${buildingId}/department/${department_id}`);
  }

  public getBuildingCategoriesData(buildingId: number, departmentId: number): Observable<any> {
    return this.http.get(`building/${buildingId}/categories/${departmentId}`);
  }

  public getBuildingCategoryData(buildingId: number, departmentId: number): Observable<IBuildingCategory> {
    return this.http.get<IBuildingCategory>(`building/${buildingId}/category/${departmentId}`);
  }

  public updateBuildingCategoryData(buildingId: number, categoryId: number, data: any): Observable<IBuildingCategory> {
    return this.http.put<IBuildingCategory>(`building/${buildingId}/category/${categoryId}`, data);
  }

  public updateBuildingData(buildingId: number, building: IRFPBuilding | IBuildingResponse): Observable<IRFPBuilding> {
    return this.http.patch<IRFPBuilding>(`building/${buildingId}`, building);
  }

  public deleteBuildingData(buildingID: number): Observable<IRFPBuilding> {
    return this.http.delete<IRFPBuilding>(`building/${buildingID}`);
  }

  public addIdenticalBuilding(id: number, count: number): Observable<any> {
    return this.http.post(`building/create-identical/${id}`, { value: count });
  }

  public removeIdenticalBuilding(id: number, count: number): Observable<any> {
    return this.http.post(`building/delete-identical/${id}`, { value: count });
  }

  public addSimilarBuilding(id: number, building: IRFPBuilding | IBuildingResponse): Observable<any> {
    return this.http.post(`building/create-similar/${id}`, building);
  }

  public addCategoryToBuilding(buildingId: number, categoryId: number): Observable<any> {
    return this.http.post(`building/${buildingId}/category/${categoryId}`, {});
  }

  public updateServiceOfBuildingStatus(data: IBuildingServiceStatus): Observable<any> {
    return this.http.patch(`building/${data.buildingId}/service/${data.id}/status`, data);
  }

  public deleteCategoryFromBuilding(buildingId: number, categoryId: number): Observable<any> {
    return this.http.delete(`building/${buildingId}/category/${categoryId}`);
  }

  public addDepartmentToBuilding(buildingId: number, departmentId: number): Observable<any> {
    return this.http.post(`building/${buildingId}/department/${departmentId}`, {});
  }

  public deleteDepartmentOfBuilding(buildingId: number, departmentId: number): Observable<any> {
    return this.http.delete(`building/${buildingId}/department/${departmentId}`);
  }

  public addBuildingCopy(rfp_id: number): Observable<any> {
    return this.http.post(`building/${rfp_id}`, {});
  }
}
