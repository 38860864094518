import { IDepartmentLineItemsGroup } from '@common/interfaces/line-items.interface';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as LineItemsActions from './line-items.actions';

export interface ILineItemsState {
  departmentLineItems: IDepartmentLineItemsGroup[] | [];
  isLoading: boolean;
}

export const initialLineItemsState: ILineItemsState = {
  departmentLineItems: [],
  isLoading: false,
};
export const lineItemsReducer = createReducer(
  initialLineItemsState,
  on(LineItemsActions.ClearLineItemsStoreAction, (): ILineItemsState => ({ ...initialLineItemsState })),

  on(
    LineItemsActions.SetDepartmentLineItemsAction,
    (state: ILineItemsState, { departmentLineItems }): ILineItemsState => ({
      ...state,
      departmentLineItems,
      isLoading: false,
    })
  )
);

export const selectLineItemsState = createFeatureSelector<ILineItemsState>('LineItems');

export const selectDepartmentLineItemsData = createSelector(selectLineItemsState, (state: ILineItemsState) => {
  return state.departmentLineItems;
});
