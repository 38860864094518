import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { IUserGroupOrTeam } from '@common/interfaces/groups.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GroupsApiService {
  constructor(private http: HttpClient) {}

  public getUserGroups(
    params?: IParamsRequestKeys
  ): Observable<IUserGroupOrTeam> {
    return this.http.get<IUserGroupOrTeam>('user-groups');
  }

  public addGroup(group: IUserGroupOrTeam): Observable<any> {
    return this.http.post('user-group', group);
  }

  public updateGroup(group: IUserGroupOrTeam, id: number): Observable<any> {
    return this.http.put(`user-group/${id}`, group);
  }

  public deleteGroup(id: number): Observable<any> {
    return this.http.delete(`user-group/${id}`);
  }
}
