import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { ICategoryRequest, ICategoryResponse } from '@common/interfaces/category.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CategoriesApiService {
  constructor(private http: HttpClient) {}

  public getCategoriesList(params?: IParamsRequestKeys): Observable<IRes<ICategoryResponse>> {
    return this.http.get<IRes<ICategoryResponse>>('categories', { params });
  }

  public addCategory(data: any): Observable<any> {
    return this.http.post('category', data);
  }

  public getCategory(id: number): Observable<ICategoryResponse> {
    return this.http.get<ICategoryResponse>(`category/${id}`);
  }

  public createCategory(data: ICategoryRequest): Observable<ICategoryRequest> {
    return this.http.post<ICategoryRequest>('category', data);
  }

  public updateCategory(data: ICategoryRequest, id: number): Observable<ICategoryRequest> {
    return this.http.put<ICategoryRequest>(`category/${id}`, data);
  }

  public deleteCategory(id: number): Observable<any> {
    return this.http.delete(`category/${id}`);
  }
}
