import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthModule } from '@auth0/auth0-angular';
import { ChipsInputModule } from '@common/modules/UI/chips-input/chips-input.module';
import { SelectModule } from '@common/modules/UI/select/select.module';
import { GlobalErrorModule } from '@core/interceptors/error/error.module';
import { TokenInterceptor } from '@core/interceptors/token.inteseptor';
import { UrlInterceptor } from '@core/interceptors/url.iterceptor';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Effects } from './store/app/app.effects';
import { appReducer, metaReducers } from './store/app/app.reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    SelectModule,
    ChipsInputModule,
    GlobalErrorModule,
    MatSnackBarModule,
    EffectsModule.forRoot(Effects),
    StoreModule.forRoot(appReducer, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.clientId,
      authorizationParams: {
        audience: environment.audience,
        redirect_uri: window.location.origin,
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: UnAuthInterceptor, multi: true },
  ],
})
export class CoreModule {}
