import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICodesNormativesInterface } from '@common/interfaces/codes-normatives.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProposalCodesNormativesApiService {
  constructor(private http: HttpClient) {}

  public getProposalCodesAnNormatives(proposalId: number): Observable<ICodesNormativesInterface[]> {
    return this.http.get<ICodesNormativesInterface[]>(`code-and-normative/${proposalId}`);
  }

  public updateProposalCodesAnNormatives(proposalId: number, data: any): Observable<ICodesNormativesInterface[]> {
    return this.http.put<ICodesNormativesInterface[]>(`code-and-normative/${proposalId}`, data);
  }

  public deleteProposalCodeAndNormative(codeNormativeId: number): Observable<void> {
    return this.http.delete<void>(`code-and-normative/${codeNormativeId}`);
  }
}
