import { Injectable } from '@angular/core';
import { BuildingApiService } from '@api/building-api.service';
import { IRes } from '@common/interfaces/base-table.interface';
import { IBuildingResponse } from '@common/interfaces/building-response.interface';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as BuildingsActions from './buildings.actions';

@Injectable()
export class BuildingsEffects {
  constructor(
    private actions$: Actions,
    private buildingApiService: BuildingApiService,
    private store: Store
  ) {}

  GetBuildingsListData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildingsActions.GetBuildingsListAction),
      filter(({ proposalId }) => !!proposalId),
      switchMap(({ proposalId }: { proposalId: number }) => {
        return this.buildingApiService.getProposalBuildings(proposalId);
      }),
      map((res: IRes<IBuildingResponse>) => res.items),
      map(buildingsList => {
        const selectedBuildingId: number | null = buildingsList && buildingsList.length ? buildingsList[0].id : null;
        return BuildingsActions.SetBuildingsListAction({ buildingsList, selectedBuildingId });
      })
    );
  });
}
