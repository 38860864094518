import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import * as io from 'socket.io-client';
@Injectable({ providedIn: 'root' })
export class SocketService {
  public host!: string;
  private socket: any;
  public token!: string;
  constructor() {
    this.socket = io.connect(environment.host, { secure: true });
  }

  public connect(): void {
    this.socket.on('connection', this.token, (socket: any) => {
      console.log('a user connected', socket);
      socket.on('disconnect', () => {
        console.log('user disconnected');
      });
    });

    // this.socket.emit('join', this.token);
  }
}
