import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseData } from '@common/interfaces/base.interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SustainabilityUtilsApiService {
  constructor(private http: HttpClient) {}

  public getBuildGreenLevelsList(): Observable<IBaseData[]> {
    return this.http.get<IBaseData[]>('build-green-levels');
  }
}
