import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IBaseData, IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { IBuildingResponse, IBuildingServiceStatus } from '@common/interfaces/building-response.interface';
import { IProposalPrice } from '@common/interfaces/proposal-price.interface';
import {
  IAdvancedRFPRequestData,
  IBaseRFPRequestData,
  IProposalBaseData,
  IRFPBuilding,
  IRFPResponse,
} from '@common/interfaces/proposal.interface';
import { ProposalAddressData } from '@core/store/proposal-info/proposal-info.actions';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProposalApiService {
  constructor(private http: HttpClient) {}

  public getProposalList(params?: IParamsRequestKeys): Observable<IRes<IBaseData>> {
    return this.http.get<IRes<IBaseData>>('rfps', { params });
  }

  public getProposal(id: number): Observable<IRFPResponse> {
    return this.http.get<IRFPResponse>(`rfp/${id}`);
  }

  public saveRFP(data: IBaseRFPRequestData): Observable<IBaseRFPRequestData> {
    return this.http.post<IBaseRFPRequestData>('rfp', data);
  }

  public updateRFP(data: IAdvancedRFPRequestData | IBaseRFPRequestData): Observable<IAdvancedRFPRequestData> {
    return this.http.patch<IAdvancedRFPRequestData>(`rfp/${data.id}`, data);
  }

  public getRFPMainData(proposalId: number): Observable<IProposalBaseData> {
    return this.http.get<IProposalBaseData>(`rfp-main/${proposalId}`);
  }

  public getRFPAddressData(proposalId: number): Observable<ProposalAddressData> {
    return this.http.get<ProposalAddressData>(`rfp-address/${proposalId}`);
  }

  public putRFPAddressData(proposalId: number, data: any): Observable<any> {
    return this.http.put<ProposalAddressData>(`rfp-address/${proposalId}`, data);
  }

  public getRFPProjectData(proposalId: number): Observable<any> {
    return this.http.get<any>(`rfp-project/${proposalId}`);
  }

  public putRFPProjectData(proposalId: number, data: any): Observable<any> {
    return this.http.put<any>(`rfp-project/${proposalId}`, data);
  }

  public getRFPTeamsData(proposalId: number): Observable<any> {
    return this.http.get<any>(`rfp-teams/${proposalId}`);
  }

  public putRFPTeamsData(proposalId: number, data: any): Observable<any> {
    return this.http.put<any>(`rfp-teams/${proposalId}`, data);
  }

  public getRFPDatesData(proposalId: number): Observable<any> {
    return this.http.get<any>(`rfp-dates/${proposalId}`);
  }

  public putRFPDatesData(proposalId: number, data: any): Observable<any> {
    return this.http.put<any>(`rfp-dates/${proposalId}`, data);
  }

  public updateRFPMainData(data: IBaseRFPRequestData, id: number): Observable<IAdvancedRFPRequestData> {
    return this.http.put<IAdvancedRFPRequestData>(`rfp/${id}`, data);
  }

  // public updateRFPAdvancedData(data: Partial<IAdvancedFormData>): Observable<Partial<IRFPFormData>> {
  //     return this.http.patch(`rfp/${data.id}`, data);
  // }

  public deleteRFP(id: number): Observable<any> {
    return this.http.delete(`rfp/${id}`);
  }

  public getProjectConditions(): Observable<IRes<IBaseData[]>> {
    return this.http.get<IRes<IBaseData[]>>('project-conditions');
  }

  public getDesignPhases(): Observable<IRes<IBaseData[]>> {
    return this.http.get<IRes<IBaseData[]>>('design-phases');
  }

  public getConstructionPhases(): Observable<IRes<IBaseData>> {
    return this.http.get<IRes<IBaseData>>('construction-phases');
  }

  // Departments
  public addDepartmentToBuilding(buildingId: number, departmentId: number): Observable<any> {
    return this.http.post(`building/${buildingId}/department/${departmentId}`, {});
  }

  public deleteDepartmentOfBuilding(buildingId: number, departmentId: number): Observable<any> {
    return this.http.delete(`building/${buildingId}/department/${departmentId}`);
  }

  public getRFPConfigs(rfpId: number): Observable<any> {
    return this.http.get(`rfp-config/${rfpId}`);
  }

  public saveRFPConfigs(data: any): Observable<any> {
    return this.http.post(`rfp-config`, data);
  }

  //  Buildings

  public addBuildingToProposal(building: IRFPBuilding | IBuildingResponse): Observable<any> {
    return this.http.post('building', building);
  }

  public getProposalBuildings(rfpID: number): Observable<IRes<IBuildingResponse>> {
    return this.http.get<IRes<IBuildingResponse>>(`rfp-buildings/${rfpID}`);
  }

  public getBuildingData(buildingID: number): Observable<IBuildingResponse> {
    return this.http.get<IBuildingResponse>(`building/${buildingID}`);
  }

  public updateBuildingData(id: number, building: IRFPBuilding | IBuildingResponse): Observable<IRFPBuilding> {
    return this.http.patch<IRFPBuilding>(`building/${id}`, building);
  }

  public deleteBuildingData(buildingID: number): Observable<IRFPBuilding> {
    return this.http.delete<IRFPBuilding>(`building/${buildingID}`);
  }

  public addIdenticalBuilding(id: number, count: number): Observable<any> {
    return this.http.post(`building/create-identical/${id}`, { value: count });
  }

  public removeIdenticalBuilding(id: number, count: number): Observable<any> {
    return this.http.post(`building/delete-identical/${id}`, { value: count });
  }

  public addSimilarBuilding(id: number, building: IRFPBuilding | IBuildingResponse): Observable<any> {
    return this.http.post(`building/create-similar/${id}`, building);
  }

  public addServiceToBuilding(data: IBuildingServiceStatus): Observable<any> {
    return this.http.post(`building/${data.buildingId}/service/${data.serviceId}`, data);
  }

  public updateServiceOfBuildingStatus(data: IBuildingServiceStatus): Observable<any> {
    return this.http.patch(`building/${data.buildingId}/service/${data.id}/status`, data);
  }

  public deleteServiceOfBuilding(data: IBuildingServiceStatus): Observable<any> {
    return this.http.delete(`building/${data.buildingId}/service/${data.serviceId}`);
  }

  //     Occupancies

  public getOccupanciesList(): Observable<any> {
    return this.http.get('occupations');
  }

  //     Price

  public getRFPPrice(rfpId: number): Observable<IProposalPrice> {
    return this.http.get<IProposalPrice>(`cost/rfp/${rfpId}`);
  }

  public setRFPPrice(rfpId: number, base_price: number, new_price: number): Observable<any> {
    return this.http.patch(`discount/rfp/${rfpId}`, { base_price, new_price });
  }

  public setBuildingPrice(buildingId: number, base_price: number, new_price: number): Observable<any> {
    return this.http.patch(`discount/building/${buildingId}`, { base_price, new_price });
  }

  public setItemPrice(
    buildingCategoryId: number,
    lineItemTemplateId: number,
    base_price: number,
    new_price: number
  ): Observable<any> {
    return this.http.patch(
      `discount/building-category/${buildingCategoryId}/line-item-template/${lineItemTemplateId}`,
      { base_price, new_price }
    );
  }

  //     RFP Preview
  public getRFPPreview(rfpId: number): Observable<any> {
    return this.http.get(`rfp/${rfpId}/descriptions`);
  }

  //     Discount

  public setRFPDiscount(rfpId: number, discount: number): Observable<any> {
    return this.http.patch(`discount/rfp/${rfpId}`, { discount });
  }

  public setBuildingDiscount(buildingId: number, discount: number): Observable<any> {
    return this.http.patch(`discount/building/${buildingId}`, { discount });
  }

  // public setItemDiscount(buildingId: number, lineItemId: number, discount: number): Observable<any> {
  //   return this.http.patch(`discount/building/${buildingId}/line-item/${lineItemId}`, { discount });
  // }

  public setItemDiscount(buildingCategoryId: number, lineItemTemplateId: number, discount: number): Observable<any> {
    return this.http.patch(
      `discount/building-category/${buildingCategoryId}/line-item-template/${lineItemTemplateId}`,
      { discount }
    );
  }

  // PDF
  public getRfpPdf(rfpId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      responseType: 'blob',
      Accept: 'application/pdf',
      observe: 'response',
    });

    const blobType = 'application/pdf';
    return this.http
      .get(`create-pdf/${rfpId}`, {
        headers: headers,
        responseType: 'blob',
      })
      .pipe
      // map((res: any) => new Blob([res], { type: blobType }))
      ();
  }

  public getNewRfpPdf(rfpId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      responseType: 'blob',
      Accept: 'application/pdf',
      observe: 'response',
    });

    const blobType = 'application/pdf';
    return this.http
      .get(`create-pdf-new/${rfpId}`, {
        headers: headers,
        responseType: 'blob',
      })
      .pipe
      // map((res: any) => new Blob([res], { type: blobType }))
      ();
  }
}
