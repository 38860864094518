import { BuildingEffects } from '@app-store/building/building.effects';
import { BuildingsEffects } from '@app-store/buildings/buildings.effects';
import { CategoriesAndNormativesEffects } from '@app-store/categories-and-codes/categories-and-codes.effects';
import { LineItemsEffects } from '@app-store/line-items/line-items.effects';
import { _ProposalEffects } from '@core/store/_proposal/_proposal.effects';
import { AuthEffects } from '../auth/auth.effects';
import { UserEffects } from '../user/user.effects';

export const Effects = [
  AuthEffects,
  UserEffects,
  _ProposalEffects,
  BuildingsEffects,
  BuildingEffects,
  CategoriesAndNormativesEffects,
  LineItemsEffects,
];
