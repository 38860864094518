import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { IUserGroupOrTeam } from '@common/interfaces/groups.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TeamsApiService {
  constructor(private http: HttpClient) {}

  public getUserTeams(
    params?: IParamsRequestKeys
  ): Observable<IUserGroupOrTeam> {
    return this.http.get<IUserGroupOrTeam>('teams');
  }

  public addTeam(group: IUserGroupOrTeam): Observable<any> {
    return this.http.post('team', group);
  }

  public updateTeam(group: IUserGroupOrTeam, id: number): Observable<any> {
    return this.http.put(`team/${id}`, group);
  }

  public deleteTeam(id: number): Observable<any> {
    return this.http.delete(`team/${id}`);
  }
}
