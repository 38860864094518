import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { filter, shareReplay, startWith, switchMap } from 'rxjs/operators';

type TUpdateCache<K = any> = {
  mapCacheKey: K;
};

export interface ICache {
  add<T = any>(service: T, key: keyof T, ...data: any[]): this;

  get<S = any, R = any>(mapCacheKey: keyof S | string): Observable<R>;

  has?<S = any>(mapCacheKey: keyof S): boolean;

  removeBy?<S = any>(key: keyof S): void;

  updateCache?<T = any>(data: TUpdateCache<keyof T | string>): void;

  updateAllCaches?(): void;

  getJustAddedCache?<R = any>(): Observable<R>;
}

@Injectable({
  providedIn: 'root',
})
export abstract class CacheService implements ICache, OnDestroy {
  private mapCaches: Map<string, Observable<any>> = new Map<string, Observable<any>>();
  private updateCache$ = new ReplaySubject<TUpdateCache>(1);
  private tempMethodService!: string;

  protected constructor() {}

  public add<T = any>(service: T, key: keyof T, ...data: any[]): this {
    const serviceMethodName = key as string;
    this.tempMethodService = serviceMethodName;

    if (!this.mapCaches.get(serviceMethodName)) {
      this.mapCaches.set(
        serviceMethodName,
        this.updateCache$.pipe(
          startWith(null),
          filter(d => d === null || (d && d.mapCacheKey === serviceMethodName)),
          switchMap(() =>
            data.length > 0
              ? // @ts-ignore
                (service[serviceMethodName] as (...args: any[]) => Observable<any>)(...data)
              : // @ts-ignore
                (service[serviceMethodName] as () => Observable<any>)()
          ),
          shareReplay(1)
        )
      );
    }
    return this;
  }

  public getJustAddedCache<R = any>(): Observable<R> {
    return this.get(this.tempMethodService);
  }

  public has<S = any>(mapCacheKey: keyof S): boolean {
    return this.mapCaches.has(<string>mapCacheKey);
  }

  public get<S = any, R = any>(mapCacheKey: keyof S | string): Observable<R> {
    const streamFromCache$ = this.mapCaches.get(mapCacheKey as string);

    return (this.has(mapCacheKey as string) && streamFromCache$) || EMPTY;
  }

  public updateCache<S = any>(data: TUpdateCache<keyof S | string>): void {
    this.updateCache$.next(data);
  }

  public updateAll(): void {
    this.mapCaches.forEach((_ /* Observable<any> */, mapCacheKey: string) => this.updateCache({ mapCacheKey }));
  }

  public removeBy<S = any>(key: keyof S): void {
    this.mapCaches.delete(<string>key);
  }

  public removeAll(): void {
    this.mapCaches.forEach((_ /* Observable<any> */, removeKey: string) => this.removeBy(removeKey));
  }

  public ngOnDestroy(): void {
    this.updateCache$.complete();
    this.mapCaches.clear();
  }
}
