<div class="chips-wrapper">
  <div class="chips-top-hint">
    <ng-content select="chips-top-hint"></ng-content>
  </div>
  <mat-form-field class="example-chip-list">
    <mat-chip-grid #chipGrid aria-label="Enter text">
      <mat-chip-row
        *ngFor="let item of chips; let i = index"
        (removed)="remove(item)"
        [editable]="true"
        (edited)="edit($event, i)"
        [aria-description]="'press enter to edit ' + item">
        {{ item }}
        <button matChipRemove [attr.aria-label]="'remove ' + item">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        [placeholder]="placeholder"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)" />
    </mat-chip-grid>
  </mat-form-field>
</div>
