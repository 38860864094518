import { proposalReducer } from '@app-store/_proposal/_proposal.reducer';
import { buildingReducer } from '@app-store/building/building.reducer';
import { buildingsReducer } from '@app-store/buildings/buildings.reducer';
import { categoriesAndCodesReducer } from '@app-store/categories-and-codes/categories-and-codes.reducer';
import { lineItemsReducer } from '@app-store/line-items/line-items.reducer';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AUTH_KEY, authReducer } from '../auth/auth.reducers';
import { userReducer } from '../user/user.reducers';

export const appReducer = {
  [AUTH_KEY]: authReducer,
  user: userReducer,
  proposal: proposalReducer,
  buildingsData: buildingsReducer,
  buildingData: buildingReducer,
  CategoriesNormatives: categoriesAndCodesReducer,
  LineItems: lineItemsReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{ [AUTH_KEY]: ['accessToken'] }],
    rehydrate: true,
    storage: localStorage,
  })(reducer);
}

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action !== null && action.type === 'CLEAR_STATE') {
      return reducer(undefined, { type: 'CLEAR_STATE' });
    }
    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, clearState];
