import { ICredentials } from '@common/interfaces/auth.interface';
import { createAction, props } from '@ngrx/store';

export const LogInRequestAction = createAction('[Auth] Login Request', props<{ credentials: ICredentials }>());
export const LogInResponseAction = createAction('[Auth] Login Response', props<{ accessToken: string }>());
export const LogInSuccessAction = createAction('[Auth] Login Success');
export const LogInResponseErrorAction = createAction('[Auth] Login Error', props<{ error: string }>());
export const LogOutAction = createAction('[Auth] Logout');

export const SetIsAuthAction = createAction('[Auth] Set isAuth', props<{ isAuth: boolean }>());
export const SetAuthTokenAction = createAction('[Auth] Set Token', props<{ accessToken: string }>());

export const InitAuthUserAction = createAction('[Auth] Init User');
