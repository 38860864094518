import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProposalApiService } from '@api/proposal-api.service';
import { StandardsApiService } from '@api/standards-api.service';
import { INormativeWithVersion } from '@common/interfaces/normative.interface';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import * as CodesAndNormativesAction from './categories-and-codes.actions';

@Injectable()
export class CategoriesAndNormativesEffects {
  constructor(
    private actions$: Actions,
    private proposalApiService: ProposalApiService,
    private standardsApiService: StandardsApiService,
    private router: Router,
    private store: Store
  ) {}

  getEnergyCategoriesAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CodesAndNormativesAction.GetCategoriesAction),
      switchMap(() => this.standardsApiService.getStandardTypesListWithVersions({ standard_id: 1 })),
      map(res => res.map(el => thansformVersion(el))),
      map(energyCodes => {
        return CodesAndNormativesAction.SetEnergyCategoriesAction({ energyCodes });
      })
    );
  });

  getSustainabilityCategoriesAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CodesAndNormativesAction.GetCategoriesAction),
      switchMap(() => this.standardsApiService.getStandardTypesListWithVersions({ standard_id: 2 })),
      map(res => res.map(el => thansformVersion(el))),
      map(sustainabilityCodes => {
        return CodesAndNormativesAction.SetSustainabilityCategoriesAction({ sustainabilityCodes });
      })
    );
  });

  getEnvelopCategoriesAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CodesAndNormativesAction.GetCategoriesAction),
      switchMap(() => this.standardsApiService.getStandardTypesListWithVersions({ standard_id: 3 })),
      map(res => res.map(el => thansformVersion(el))),
      map(envelopCodes => {
        return CodesAndNormativesAction.SetEnvelopCategoriesAction({ envelopCodes });
      })
    );
  });
}

function thansformVersion(normative: INormativeWithVersion): INormativeWithVersion {
  normative.versions.forEach(el => (el.name = `${normative.abbreviation} ${el.name} (${el.provision})`));
  return normative;
}
