import { IProposalBaseData } from '@common/interfaces/proposal.interface';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as ProposalActions from '../_proposal/_proposal.actions';

export interface ProposalState {
  proposalId: number | null;
  proposalMainData: IProposalBaseData | null;
  codesAndNormatives: any[];
  isLoading: boolean;
  error: string | null;
}

export const initialProposalState: ProposalState = {
  proposalMainData: null,
  proposalId: null,
  codesAndNormatives: [],
  isLoading: false,
  error: null,
};
export const _proposalReducer = createReducer(
  initialProposalState,
  on(
    ProposalActions.GetProposalMainDataAction,
    (state: ProposalState, { proposalId }): ProposalState => ({
      ...state,
      proposalId,
      isLoading: true,
      error: null,
    })
  ),
  on(
    ProposalActions.SetProposalMainDataAction,
    (state: ProposalState, { proposalMainData, proposalId }): ProposalState => ({
      ...state,
      proposalMainData,
      isLoading: true,
      proposalId: proposalId,
      error: null,
    })
  ),
  on(ProposalActions.ClearProposalStoreAction, (): ProposalState => ({ ...initialProposalState })),
  on(
    ProposalActions.GetProposalCodesAndNormatives,
    (state: ProposalState): ProposalState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    ProposalActions.SetProposalCodesAndNormatives,
    (state: ProposalState, { codesAndNormatives }): ProposalState => ({
      ...state,
      codesAndNormatives,
      isLoading: false,
    })
  ),
  on(ProposalActions.DeleteProposalCodesAndNormatives, (state: ProposalState) => ({
    ...state,
    isLoading: true,
  }))
);

export const selectProposalState = createFeatureSelector<ProposalState>('proposal');

export const selectProposalMainData = createSelector(selectProposalState, (state: ProposalState) => {
  return state.proposalMainData;
});

export const selectProposalId = createSelector(selectProposalState, (state: ProposalState) => {
  return state.proposalId;
});

export const selectCodesAndNormatives = createSelector(selectProposalState, (state: ProposalState) => {
  return state?.codesAndNormatives;
});

export const dataIsLoading = createSelector(selectProposalState, state => {
  return state?.isLoading;
});

export function proposalReducer(state: ProposalState | undefined, action: Action) {
  return _proposalReducer(state, action);
}
