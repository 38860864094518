<div class="select-wrapper" [ngClass]="'hint-' + hintPosition" [class.has-footer-tmp]="!!footerTemplate">
  <div class="select-top-hint" *ngIf="selectTopHintChildren.length > 0">
    <!-- Condition for render top hint template -->
    <ng-content select="select-top-hint"></ng-content>
  </div>

  <div class="select-container {{ styleClass }}" #select>
    <ng-select
      [class.areas-select-multiple]="multiple"
      [appendTo]="appendTo"
      [searchable]="searchable"
      [bindValue]="bindValue"
      [bindLabel]="bindLabel"
      [formControl]="ngSelectControl"
      [clearable]="clearable"
      [readonly]="readonly"
      [items]="items"
      [dropdownPosition]="dropdownPosition"
      [placeholder]="placeholder"
      [multiple]="multiple || multipleWithoutCheckboxes"
      [closeOnSelect]="closeOnSelect"
      [compareWith]="compareWith"
      [selectableGroup]="selectableGroup"
      [selectableGroupAsModel]="selectableGroupAsModel"
      [hideSelected]="hideSelected"
      [groupBy]="groupBy"
      [groupValue]="groupValue"
      [minTermLength]="minTermLength"
      (search)="searchEmit.next($event)"
      (close)="onTouched()"
      (change)="changeHandler($event)"
      [typeahead]="typeahead"
      [typeToSearchText]="typeToSearchText"
      [trackByFn]="trackByFn"
      [loading]="loading"
      [searchFn]="searchFn"
      [notFoundText]="'No Items Found'"
      [keyDownFn]="keyDownFn"
      (open)="focusEvent(); onOpen($event)"
      (blur)="onBlur()"
      (clear)="onClear()"
      (keydown.enter)="onKeydownEnter($event)"
      [ngClass]="{ 'list-fixed': dropdownListPositionFixed }">
      <ng-container *ngIf="headerTemplate">
        <ng-template ng-header-tmp>
          <ng-template *ngTemplateOutlet="headerTemplate"></ng-template>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="optGroupTemplate && !multiple">
        <ng-template ng-optgroup-tmp let-item="item">
          <ng-template *ngTemplateOutlet="optGroupTemplate; context: { $implicit: item }"></ng-template>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="multiLabelTemplate">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <ng-template
            *ngTemplateOutlet="multiLabelTemplate; context: { $implicit: items, clear: clear }"></ng-template>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="multiple">
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
          <div class="multiselect-item">
            <input type="checkbox" [checked]="item$.selected" />
            <!-- <mat-checkbox class="multiselect-item__checkbox" [checked]="item$.selected"></mat-checkbox> -->
            <div class="multiselect-item__name">
              <ng-container *ngIf="optGroupTemplate">
                <ng-template
                  *ngTemplateOutlet="optGroupTemplate; context: { $implicit: item, item$: item$ }"></ng-template>
              </ng-container>
              <ng-container *ngIf="!optGroupTemplate">{{ item[groupBy] }}</ng-container>
            </div>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="multiselect-item">
            <input type="checkbox" [checked]="item$.selected" [disabled]="item$.disabled" />
            <div class="multiselect-item__name" [ngClass]="{ disabled: item$.disabled }">
              <ng-container *ngIf="optionTemplate">
                <ng-template
                  *ngTemplateOutlet="
                    optionTemplate;
                    context: { $implicit: item, index: index, item$: item$ }
                  "></ng-template>
              </ng-container>
              <ng-container *ngIf="!optionTemplate">{{ item[bindLabel] }}</ng-container>
            </div>
          </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <ng-container *ngIf="labelTemplate">
            <ng-template *ngTemplateOutlet="labelTemplate; context: { $implicit: item }"></ng-template>
            {{ ', ' }}
          </ng-container>
          <ng-container *ngIf="!labelTemplate">
            {{ item[bindLabel] + ', ' }}
          </ng-container>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="optionTemplate && !multiple">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <ng-template
            *ngTemplateOutlet="optionTemplate; context: { $implicit: item, index: index, item$: item$ }"></ng-template>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="labelTemplate && !multiple">
        <ng-template ng-label-tmp let-item="item">
          <div class="single-item__name" [ngClass]="{ disabled: item.disabled }">
            <ng-template *ngTemplateOutlet="labelTemplate; context: { $implicit: item }"></ng-template>
          </div>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="notFountTemplate">
        <ng-template ng-notfound-tmp>
          <ng-template *ngTemplateOutlet="notFountTemplate"></ng-template>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="footerTemplate">
        <ng-template ng-footer-tmp>
          <ng-template *ngTemplateOutlet="footerTemplate"></ng-template>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="typeHeadTemplate">
        <ng-template ng-typetosearch-tmp>
          <ng-template *ngTemplateOutlet="typeHeadTemplate"></ng-template>
        </ng-template>
      </ng-container>
    </ng-select>

    <ng-container *ngIf="iconTemplate; else defaultIconTmp">
      <div class="select-icon" (click)="openDropPanelHandler()">
        <ng-template *ngTemplateOutlet="iconTemplate"></ng-template>
      </div>
    </ng-container>

    <div class="select-error">
      <!-- Condition for render error template -->
      <ng-content select="select-error"></ng-content>
    </div>
  </div>

  <ng-template #defaultIconTmp>
    <div class="select-icon">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </ng-template>
</div>
