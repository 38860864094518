import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthCustomService } from '@api/auth.service';
import { LogOutAction } from '@core/store/auth/auth.actions';
import { selectAccessToken } from '@core/store/auth/auth.reducers';
import { Store } from '@ngrx/store';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthCustomService,
    private store: Store
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store.select(selectAccessToken).pipe(
      first(),
      switchMap(accessToken => {
        if (accessToken) {
          request = this.addTokenToRequest(request, accessToken);
        }

        return next.handle(request).pipe(
          catchError(({ error }) => {
            if ((error.status === 400 && error.message == 'The user does not exist.') || error.status === 401) {
              this.store.dispatch(LogOutAction());
              return next.handle(request);
              // return this.authService.refreshToken().pipe(
              //   catchError(({ status, message }) => {
              //     if (status === 401 && message == 'No Token') {
              //       this.store.dispatch(LogOutAction());
              //     }
              //     return of(null);
              //   }),
              //   filter(res => !!res),
              //   switchMap(({ accessToken }) => {
              //     this.store.dispatch(SetAuthTokenAction({ accessToken }));
              //     request = this.addTokenToRequest(request, accessToken);
              //     return next.handle(request);
              //   })
              // );
            } else {
              return throwError(() => error.message);
            }
          })
        );
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
