import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as AuthAction from './auth.actions';

export const AUTH_KEY = 'bee-token';
export const accessToken = 'accessToken';

export interface AuthState {
  [accessToken]: string | null;
  isAuth: boolean;
  isLoading: boolean;
  error: string | null;
}

export const initialState: AuthState = {
  accessToken: null,
  isAuth: false,
  isLoading: false,
  error: null,
};

const _authReducer = createReducer(
  initialState,
  on(
    AuthAction.LogInRequestAction,
    (state): AuthState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    AuthAction.LogInResponseAction,
    (state, { accessToken }): AuthState => ({
      ...state,
      accessToken,
      isLoading: false,
      isAuth: true,
    })
  ),
  on(
    AuthAction.SetIsAuthAction,
    (state, { isAuth }): AuthState => ({
      ...state,
      isAuth,
    })
  ),
  on(
    AuthAction.SetAuthTokenAction,
    (state, { accessToken }): AuthState => ({
      ...state,
      accessToken,
    })
  ),
  // on(
  //   AuthAction.LogInResponseErrorAction,
  //   (state, { error }): AuthState => ({
  //     ...state,
  //     ...initialState,
  //     error,
  //   })
  // ),
  on(AuthAction.LogOutAction, (): AuthState => ({ ...initialState }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return _authReducer(state, action);
}

export const selectAuthState = createFeatureSelector<AuthState>(AUTH_KEY);
export const selectIsAuth = createSelector(selectAuthState, (state: AuthState) => state.isAuth);
export const selectAccessToken = createSelector(selectAuthState, (state: AuthState) => state.accessToken);
