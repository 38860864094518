import { Component } from '@angular/core';
import { IUser } from '@common/interfaces/user.interface';
import * as AuthActions from '@core/store/auth/auth.actions';
import { selectUser } from '@core/store/user/user.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  userData$: Observable<IUser | null> = this.store.select(selectUser);

  public isUserMenuOpen = false;
  public isUserLogged = false;

  constructor(private store: Store) {}

  public toggleMenu(): void {
    this.isUserMenuOpen = !this.isUserMenuOpen;
  }

  public hideMenu(): void {
    this.isUserMenuOpen = false;
  }

  public logOut(): void {
    this.store.dispatch(AuthActions.LogOutAction());
  }
}
