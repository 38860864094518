import { INormativeWithVersion } from '@common/interfaces/normative.interface';
import { createAction, props } from '@ngrx/store';

export const ClearCategoriesNormativesAction = createAction('[CategoriesNormatives] Clear CategoriesNormatives Store');

export const GetCategoriesAction = createAction('[CategoriesNormatives] Get Energy Categories Action Store');
export const GetEnergyCategoriesAction = createAction('[CategoriesNormatives] Get Energy Categories Action Store');

export const SetEnergyCategoriesAction = createAction(
  '[CategoriesNormatives] Set Energy Categories Action Store',
  props<{ energyCodes: INormativeWithVersion[] }>()
);

export const GetSustainabilityCategoriesAction = createAction(
  '[CategoriesNormatives] Get Sustainability Categories Action Store'
);

export const SetSustainabilityCategoriesAction = createAction(
  '[CategoriesNormatives] Set Sustainability Categories Action Store',
  props<{ sustainabilityCodes: INormativeWithVersion[] }>()
);

export const GetEnvelopCategoriesAction = createAction('[CategoriesNormatives] Get Envelop Categories Action Store');

export const SetEnvelopCategoriesAction = createAction(
  '[CategoriesNormatives] Set Envelop Categories Action Store',
  props<{ envelopCodes: INormativeWithVersion[] }>()
);
