import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserImgComponent } from './user-img.component';

@NgModule({
  declarations: [UserImgComponent],
  imports: [CommonModule, NgOptimizedImage],
  exports: [UserImgComponent],
})
export class UserImgModule {}
