import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: 'select-error',
})
export class SelectErrorDirective {
  constructor() {}
}

@Directive({
  selector: 'select-top-hint',
})
export class SelectTopHintDirective {
  constructor() {}
}

@Directive({
  selector: '[select-opt-tmp]',
})
export class SelectOptionDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[select-label-tmp]',
})
export class SelectLabelDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[select-header-tmp]',
})
export class SelectHeaderDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[select-notFount-tmp]',
})
export class SelectNotFountDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[select-footer-tmp]',
})
export class SelectFooterDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[select-typehead-tmp]',
})
export class SelectTypeHeadDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[select-multi-label-tmp]',
})
export class SelectMultiLabelDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[select-optgroup-tmp]',
})
export class SelectOptGroupDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[custom-select-delete-icon]',
})
export class SelectIconDirective {
  constructor(public template: TemplateRef<any>) {}
}
