import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ChipsInputComponent } from './chips-input.component';
import * as Directives from './chips-input.directive';

const CHIPS_COMPONENTS = [Directives.ChipsTopHintDirective, Directives.ChipsControlDirective];

@NgModule({
  declarations: [ChipsInputComponent, CHIPS_COMPONENTS],
  imports: [CommonModule, MatInputModule, MatChipsModule, MatIconModule],
  exports: [ChipsInputComponent, CHIPS_COMPONENTS],
})
export class ChipsInputModule {}
