<div class="modal-form-wrapper">
  <div class="header-container">
    <div class="header-title">
      {{ data.title }}
    </div>
    <button class="close-modal" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="main-content">
    <div class="question" [ngClass]="{ 'error-color': data.errorMessage }" *ngIf="data.text">{{ data.text }}</div>
    <!--    <div class="errors" *ngIf="data.errors.length">-->
    <!--      <span *ngFor="let item of data.errors | keyvalue"> {{ item.key }} : {{ item.value }} </span>-->
    <!--    </div>-->
  </div>
</div>
