import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IBaseData, IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OccupationApiService {
  constructor(private http: HttpClient) {}

  public getOccupations(params?: IParamsRequestKeys): Observable<IRes<IBaseData>> {
    return this.http.get<IRes<IBaseData>>('occupations', { params });
  }

  public save(name: string): Observable<IBaseData> {
    return this.http.post<IBaseData>('occupation', { name });
  }

  public update(occupationId: number, name: string): Observable<IBaseData> {
    return this.http.patch<IBaseData>(`occupation/${occupationId}`, { name });
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(`occupation/${id}`);
  }
}
