import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { ClickOutsideModule } from '@common/directives/click-outside/click-outside.module';
import { UserImgModule } from '@common/modules/UI/user-img/user-img.module';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, RouterLink, MatDialogModule, ClickOutsideModule, UserImgModule, MatIconModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
