import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectComponent } from './select.component';
import * as Directives from './select.directives';

const SELECT_COMPONENTS = [
  SelectComponent,
  Directives.SelectHeaderDirective,
  Directives.SelectErrorDirective,
  Directives.SelectTopHintDirective,
  Directives.SelectOptionDirective,
  Directives.SelectLabelDirective,
  Directives.SelectNotFountDirective,
  Directives.SelectFooterDirective,
  Directives.SelectTypeHeadDirective,
  Directives.SelectMultiLabelDirective,
  Directives.SelectOptGroupDirective,
  Directives.SelectIconDirective,
];

@NgModule({
  declarations: SELECT_COMPONENTS,
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule, MatIconModule],
  exports: SELECT_COMPONENTS,
})
export class SelectModule {}
