import { Component, Input } from '@angular/core';

@Component({
  selector: 'user-img',
  templateUrl: './user-img.component.html',
  styleUrls: ['./user-img.component.scss'],
})
export class UserImgComponent {
  @Input() userImgSrc: string | null = null;
}
