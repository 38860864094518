import { IDepartmentLineItemsGroup } from '@common/interfaces/line-items.interface';
import { createAction, props } from '@ngrx/store';

export const ClearLineItemsStoreAction = createAction('[LineItems] Clear LineItems Store');

export const GetDepartmentLineItemsAction = createAction('[LineItems] Get Department Line Items Action');

export const SetDepartmentLineItemsAction = createAction(
  '[LineItems] Set Department Line Items Action',
  props<{
    departmentLineItems: IDepartmentLineItemsGroup[];
  }>()
);
