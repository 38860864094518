import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IBaseData, IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { IDepartmentLineItemsGroup, ILineItemFromGroup } from '@common/interfaces/line-items.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LineItemsApiService {
  constructor(private http: HttpClient) {}

  public getLineItemsListByServiceId(id: number): Observable<IBaseData[]> {
    return this.http.get<IBaseData[]>(`line-items/${id}`);
  }

  public addLineItem(data: any): Observable<any> {
    return this.http.post<any>(`line-item-template`, data);
  }

  public updateLineItem(id: number, data: any): Observable<any> {
    return this.http.put<any>(`line-item-template/${id}`, data);
  }

  public deleteLineItem(id: number): Observable<any> {
    return this.http.delete<any>(`line-item-template/${id}`);
  }

  public getLineItemsList(params?: IParamsRequestKeys): Observable<IRes<IBaseData>> {
    return this.http.get<IRes<IBaseData>>(`line-item-templates`, { params });
  }

  public getLineItemFromGroup(groupId: number, params?: IParamsRequestKeys): Observable<IRes<ILineItemFromGroup>> {
    return this.http.get<IRes<ILineItemFromGroup>>(`line-item-group/${groupId}`, { params });
  }

  public getLineItemTemplate(id: number): Observable<any> {
    return this.http.get<any>(`line-item-template/${id}`);
  }

  public getLineItemsGroups(): Observable<IBaseData[]> {
    return this.http.get<IBaseData[]>('line-item-groups');
  }

  public getLineItemDepartments(): Observable<IDepartmentLineItemsGroup[]> {
    return this.http.get<IDepartmentLineItemsGroup[]>('line-item-departments');
  }

  public getLineItemsGroupsByDepartment(departmentId: number): Observable<IDepartmentLineItemsGroup[]> {
    return this.http.get<IDepartmentLineItemsGroup[]>(`line-item-groups/${departmentId}`);
  }

  public addLineItemToBuildingDepartment(
    buildingId: number,
    data: {
      line_item_template_id: number;
      department_id: number;
    }
  ): Observable<any> {
    return this.http.post(`building/${buildingId}/line-item-template`, data);
  }
}
