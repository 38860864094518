<div class="user-img-wrapper">
  <img
    ngSrc="{{ userImgSrc }}"
    alt="avatar"
    *ngIf="userImgSrc; else userDefaultImg"
    fill="true" />
</div>

<ng-template #userDefaultImg>
  <img ngSrc="./assets/icons/user.png" alt="default-user-avatar" fill="true" />
</ng-template>
