import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { selectAuthState } from '@core/store/auth/auth.reducers';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate(): Observable<true | UrlTree> {
    return this.store.select(selectAuthState).pipe(
      debounceTime(500),
      map(({ isAuth }) => (isAuth ? true : this.router.createUrlTree(['auth'])))
    );
  }

  canActivateChild(): Observable<true | UrlTree> {
    return this.canActivate();
  }
}
