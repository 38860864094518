import { HttpErrorResponse } from '@angular/common/http';

export namespace NModalError {
  export interface IModalErrorInput {
    modalName: string;
    errors?: any;
    originalRequestError: any;
  }

  export interface IModalErrorOutput {}
}

export interface IInterceptError {
  originalError: HttpErrorResponse & any;
  modifyError?: any;
}

export class InterceptError implements IInterceptError {
  constructor(
    public originalError: HttpErrorResponse & any,
    public modifyError?: any
  ) {}
}
