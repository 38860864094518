import { IUser } from '@common/interfaces/user.interface';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export interface UserState {
  user: IUser | null;
  isLoading: boolean;
  error: string | null;
}

export const initialState: UserState = {
  user: null,
  isLoading: false,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.LoadUser, (state): UserState => ({ ...state, isLoading: true, error: null })),
  on(UserActions.LoadUserSuccess, (state, { user }): UserState => ({ ...state, user, isLoading: false })),
  on(UserActions.LoadUserError, (state, { error }): UserState => ({ ...state, error, isLoading: false }))
);

export const selectUserState = createFeatureSelector<UserState>('user');
export const selectUser = createSelector(selectUserState, (state: UserState) => state.user);
export const selectIsLoading = createSelector(selectUserState, (state: UserState) => state.isLoading);
export const selectError = createSelector(selectUserState, (state: UserState) => state.error);
