import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProposalApiService } from '@api/proposal-api.service';
import { ProposalCodesNormativesApiService } from '@api/proposal-codes-normatives-api.service';
import { ICodesNormativesInterface } from '@common/interfaces/codes-normatives.interface';
import {
  IAdvancedRFPRequestData,
  IBaseRFPRequestData,
  IProposalBaseData,
  RequestForProposalDepartmentInfo,
} from '@common/interfaces/proposal.interface';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of, switchMap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import * as ProposalActions from '../_proposal/_proposal.actions';

@Injectable()
export class _ProposalEffects {
  constructor(
    private actions$: Actions,
    private proposalApiService: ProposalApiService,
    private proposalCodesNormativesApiService: ProposalCodesNormativesApiService,
    private router: Router,
    private store: Store
  ) {}

  // SaveProposalMainDataAction
  getMainProposalData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProposalActions.GetProposalMainDataAction),
      filter(res => !!res.proposalId),
      switchMap(({ proposalId }) => {
        return this.proposalApiService.getRFPMainData(proposalId).pipe(
          map((proposalMainData: IProposalBaseData | any) => {
            return ProposalActions.SetProposalMainDataAction({
              proposalMainData: transformProposalMainData(proposalMainData),
              proposalId: proposalMainData.id,
            });
          }),
          catchError(error => of(ProposalActions.LoadProposalErrorAction({ error })))
        );
      })
    );
  });

  UpdateMainProposalData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProposalActions.UpdateProposalMainDataAction),
      switchMap(
        ({ proposalFormMainData, proposalId }: { proposalFormMainData: IBaseRFPRequestData; proposalId: number }) => {
          return this.proposalApiService.updateRFPMainData(proposalFormMainData, proposalId).pipe(
            map((proposalMainData: IAdvancedRFPRequestData) => {
              return ProposalActions.SetProposalMainDataAction({
                proposalMainData: transformProposalMainData(proposalMainData),
                proposalId: proposalMainData.id,
              });
            }),
            catchError(error => of(ProposalActions.LoadProposalErrorAction({ error })))
          );
        }
      )
    );
  });

  SaveMainProposalData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProposalActions.SaveProposalMainDataAction),
      switchMap(({ proposalFormMainData }: { proposalFormMainData: IBaseRFPRequestData }) => {
        return this.proposalApiService.saveRFP(proposalFormMainData).pipe(
          map((proposalMainData: IBaseRFPRequestData) => {
            return ProposalActions.SetProposalMainDataAction({
              proposalMainData: transformProposalMainData(proposalMainData),
              proposalId: proposalMainData.id,
            });
          }),
          catchError(error => of(ProposalActions.LoadProposalErrorAction({ error })))
        );
      })
    );
  });

  GetProposalCodesAndNormatives$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProposalActions.GetProposalCodesAndNormatives),
      switchMap(({ proposalId }: { proposalId: number }) =>
        this.proposalCodesNormativesApiService.getProposalCodesAnNormatives(proposalId).pipe(
          map((data: ICodesNormativesInterface[]) => {
            const groupedObjects = data.reduce((acc: any, item) => {
              const departmentId = item.department.id;
              const departmentName = item.department.name;
              const codeStandardType = item.code_standard_type;
              codeStandardType.version = item.version;
              codeStandardType.provision = item.provision;
              codeStandardType.el_id = item.id;
              if (!acc[departmentId]) {
                acc[departmentId] = {
                  id: item.id,
                  departmentId,
                  name: departmentName,
                  code_standard_types: [],
                };
              }
              acc[departmentId].code_standard_types.push(codeStandardType);

              return acc;
            }, {});
            return Object.values(groupedObjects);
          }),
          map(groupedObjects => {
            return ProposalActions.SetProposalCodesAndNormatives({ codesAndNormatives: groupedObjects });
          })
        )
      )
    );
  });

  UpdateProposalCodesAndNormatives$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProposalActions.UpdateProposalCodesAndNormatives),
      switchMap(({ proposalId, data }: { proposalId: number; data: any }) => {
        return forkJoin(
          this.proposalCodesNormativesApiService.updateProposalCodesAnNormatives(proposalId, {
            code_and_normative: data.Energy,
            rfp_id: proposalId,
            department_id: 1,
          }),
          this.proposalCodesNormativesApiService.updateProposalCodesAnNormatives(proposalId, {
            code_and_normative: data.Sustainability,
            rfp_id: proposalId,
            department_id: 2,
          }),
          this.proposalCodesNormativesApiService.updateProposalCodesAnNormatives(proposalId, {
            code_and_normative: data.Envelop,
            rfp_id: proposalId,
            department_id: 3,
          })
        ).pipe(
          map(() => {
            return ProposalActions.GetProposalCodesAndNormatives({ proposalId });
          })
        );
      })
    );
  });

  DeleteProposalCodesAndNormatives$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProposalActions.DeleteProposalCodesAndNormatives),
      switchMap(({ codesAndNormativeId, proposalId }) =>
        this.proposalCodesNormativesApiService
          .deleteProposalCodeAndNormative(codesAndNormativeId)
          .pipe(map(() => ({ proposalId })))
      ),
      map(proposalId => ProposalActions.GetProposalCodesAndNormatives(proposalId))
    );
  });
}

function transformProposalMainData(proposalMainData: IProposalBaseData | any): IProposalBaseData {
  proposalMainData.request_for_proposal_department_infos.forEach(
    (proposalDepartment: RequestForProposalDepartmentInfo) => {
      proposalMainData[proposalDepartment.department.name.toLowerCase()] = true;
    }
  );
  delete proposalMainData.request_for_proposal_department_infos;
  return proposalMainData;
}
