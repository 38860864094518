import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { OccupancyGroup } from '@common/interfaces/occupancy-group.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OccupancyApiService {
  constructor(private http: HttpClient) {}

  public getOccupancyGroups(): Observable<IRes<OccupancyGroup>> {
    return this.http.get<IRes<OccupancyGroup>>('occupancy-groups').pipe(
      map(res => {
        res.items
          .find(el => el.id == 8)
          ?.occupancy_sub_groups.forEach(item => {
            item.name = `${item.name} (${item.description})`;
          });

        return res;
      })
    );
  }
}
