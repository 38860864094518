import { AfterViewInit, Directive, ElementRef, Optional } from '@angular/core';
import { FormControlDirective, FormControlName, NgControl } from '@angular/forms';

@Directive({
  selector: 'chips-top-hint',
})
export class ChipsTopHintDirective {}

@Directive({
  selector: 'chips-control',
})
export class ChipsControlDirective implements AfterViewInit {
  public control: NgControl;

  private placeholderDefault = '';

  constructor(
    @Optional() private formControlDirective: FormControlDirective,
    @Optional() private formControlName: FormControlName,
    private _el: ElementRef<HTMLInputElement>
  ) {
    this.control = this.formControlDirective || this.formControlName;
    this._el.nativeElement.placeholder = this._el.nativeElement.placeholder || this.placeholderDefault;
  }

  public ngAfterViewInit(): void {
    // Write some logic here
  }
}
