import { Injectable } from '@angular/core';
import { LineItemsApiService } from '@api/line-items-api.service';
import * as LineItemsActions from '@app-store/line-items/line-items.actions';
import { IDepartmentLineItemsGroup } from '@common/interfaces/line-items.interface';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LineItemsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private lineItemsApiService: LineItemsApiService
  ) {}

  GetDepartmentLineItemsAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LineItemsActions.GetDepartmentLineItemsAction),
      switchMap(() => this.lineItemsApiService.getLineItemDepartments()),
      map((departmentLineItems: IDepartmentLineItemsGroup[]) => {
        return LineItemsActions.SetDepartmentLineItemsAction({ departmentLineItems });
      })
    );
  });
}
