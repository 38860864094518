import { Injectable } from '@angular/core';
export type Theme = 'light' | 'dark' | 'auto';
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
  public currentTheme!: Theme;

  public toggleTheme(theme: Theme) {
    localStorage.setItem('theme', theme);
    this.currentTheme = theme;
    this._applyTheme(theme);
  }

  public defineTheme(): void {
    this.currentTheme = (localStorage.getItem('theme') as Theme) || 'light';
    this._applyTheme(this.currentTheme);
  }

  private _applyTheme(theme: Theme) {
    if (theme !== 'auto') {
      this._setTheme(theme);
    } else {
      this._handlingThemeChange(this.prefersDarkMode);
    }
  }

  private _handlingThemeChange(event: any): void {
    this._setTheme(event.matches ? 'dark' : 'light');
    this.prefersDarkMode.addEventListener('change', this._handlingThemeChange);
  }

  private _setTheme(theme: 'light' | 'dark'): void {
    document.body.setAttribute('data-theme', theme);
  }
}
