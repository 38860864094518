import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { IContact } from '@common/interfaces/contact.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContactApiService {
  constructor(private http: HttpClient) {}

  public saveContact(contact: IContact): Observable<any> {
    return this.http.post('contact', contact);
  }

  public getContactsList(params?: IParamsRequestKeys): Observable<IRes<IContact>> {
    return this.http.get<IRes<IContact>>('contacts', { params });
  }

  public getContact(id: number): Observable<IContact> {
    return this.http.get<IContact>(`contact/${id}`);
  }

  public getContactAssignedToRFPFlag(id: number): Observable<boolean> {
    return this.http.get<boolean>(`contact-assigned/${id}`);
  }

  public updateContact(contactId: number, contact: IContact): Observable<any> {
    return this.http.patch(`contact/${contactId}`, contact);
  }

  public deleteContact(id: number): Observable<IContact> {
    return this.http.delete<IContact>(`contact/${id}`);
  }
}
